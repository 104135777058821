let headerHeightBack = $('.c-site-header').height() + 130 ;
let headerHeightLetter = $('.c-site-header').height() + 45 ;

$(window).on('resize', function () {
     headerHeightBack = $('.c-site-header').height() + 130 ;
});
$(window).on('resize', function () {
     headerHeightLetter = $('.c-site-header').height() + 45 ;
});

if ($('.c-glossary-letters').length) {
    $('.c-glossary-letters').attr('id', 'glossary-letters')
}

if ($('.c-glossary-description__back-link').length) {
    $('.c-glossary-description__back-link').attr('href', '#glossary-letters')
}

$('.c-glossary-description__back-link').on('click',function(event){
    //event.preventDefault();
    $this = $(this);
    $(this).addClass('is-active');
    var target = $('#glossary-letters');
    var url = window.location.href.split('#')[0] + '#glossary-letters';
    $('html,body').animate({ 
        scrollTop: target.offset().top - headerHeightBack
    }, function name(params) {
        $('.c-glossary-letters__link[href="#letter-'+$this.prev('.c-glossary-description__letter').attr('id').split('-')[1]+'"]').focus();
    });
    history.pushState(null, null, url);
});

$('.c-glossary-letters__link').on('click',function(event){
    //event.preventDefault();
    $(this).addClass('is-active');
    $('.c-glossary-description__letter').removeAttr('tabindex');
    var target = $($(this).attr('href'));
    var url = window.location.href.split('#')[0] + $(this).attr('href');
    $('html,body').animate({ 
        scrollTop: target.offset().top - headerHeightLetter
    },1400, function () {
        target.attr('tabindex', 0).focus();
    });
    history.pushState(null, null, url);
});

