var alertClose = $('.js-message-alert-close');
var alertContent = $('.c-office-hero-alert');

$(function () {
    alertClose.click(function () {
        alertContent.css({
            'display': 'none',
        })
    })
})
