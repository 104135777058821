$(function() {
    if (jQuery(".js-winners-images").length <= 9) {
        jQuery(".js-winners-load-more").hide();
    } else {
        jQuery(".c-promotions-winners .js-winners-load-more").on("click", function(e) {
            e.preventDefault();

            var winners_visibles = jQuery(".js-winners-images:visible").length;
            jQuery(".js-winners-images").each(function(index) {
                    //console.log(index);
                    if (index > winners_visibles - 1 && index < winners_visibles + 8) {
                        if (jQuery(".js-winners-images").eq(index).length) {
                            jQuery(".js-winners-images").eq(index).show();
                        }
                    }
                    if (jQuery(".js-winners-images").length <= winners_visibles + 8) {
                        jQuery(".c-promotions-winners .js-winners-load-more").hide();
                    }
                })
                //console.log(winners_visibles);
        })
    }
})
$(function() {
    if (jQuery(".js-winners-images").length <= 3) {
        jQuery(".js-winners-load-more").hide();
    } else {
        jQuery(".c-promotions-winners .js-load-more").on("click", function(e) {
            e.preventDefault();

            var winners_visibles = jQuery(".js-winners-images:visible").length;
            jQuery(".js-winners-images").each(function(index) {
                    //console.log(index);
                    if (index > winners_visibles - 1 && index < winners_visibles + 2) {
                        if (jQuery(".js-winners-images").eq(index).length) {
                            jQuery(".js-winners-images").eq(index).show();
                        }
                    }
                    if (jQuery(".js-winners-images").length <= winners_visibles + 2) {
                        jQuery(".c-promotions-winners .js-load-more").hide();
                    }
                })
                //console.log(winners_visibles);
        })
    }
})
