let tabItem01 = $('.js-hero-home-tabs__item--1');
let tabItem02 = $('.js-hero-home-tabs__item--2');
let tabItem03 = $('.js-hero-home-tabs__item--3');
//let tabItem04 = $('.js-hero-home-tabs__item--4');

$(function () {
    
    tabItem01.on('click', function () {
        $('.js-line-progress').css("left","0%");
    });
    tabItem02.on('click', function () {
        $('.js-line-progress').css("left","33.33%");
    });
    tabItem03.on('click', function () {
        $('.js-line-progress').css("left","66.66%");
    });
    // tabItem04.on('click', function () {
    //     $('.js-line-progress').css("left","75%");
        
    // })
})

