jQuery(function() {
    jQuery('.js-regional-info-by-city-button').on('click',function(e){
        e.preventDefault();
        var show_items = 6;
        var total_cities = jQuery('.c-regional-page-city__link');
        var visible_cities = jQuery('.c-regional-page-city__link[data-visible="visible"]');
        console.log(total_cities);
        console.log(visible_cities);
        var count_visible = jQuery(visible_cities).length;
        for(i = 0; i<=5;i++){
            if(jQuery(total_cities).eq(count_visible + i).length){
                console.log(count_visible +i);
                jQuery(total_cities).eq(count_visible+i).show();
                jQuery(total_cities).eq(count_visible+i).attr('data-visible','visible');
            }
        }
        var new_visible = jQuery('.c-regional-page-city__link[data-visible="visible"]').length;
        if(new_visible >= jQuery(total_cities).length){
            jQuery(this).fadeOut();
        }
    });

});