jQuery(function() {
    jQuery(".js-home-post-load-more").on("click", function(e) {
        e.preventDefault();
        jQuery( this ).prop( "disabled", true );
        //console.log("get data");
        var cat = jQuery(".js-home-post-load-more").attr('data-cat');
        var paged = jQuery(".js-home-post-load-more").attr('data-paged');
        var max_paged = jQuery(".js-home-post-load-more").attr('data-maxpages');
        $.ajax({
            //url: "http://freeway.test/api/knowledge/get-paged-posts.php",
            url: window.location.origin + "/api/knowledge/get-paged-posts.php",
            data: "cat=" + cat + "&paged=" + paged,
            type: "GET",
            //dataType: "jsonp", 
            success: function(data) {
                if (data.length > 0) {
                    paged = parseInt(paged) + 1;
                    if (paged > max_paged) {
                        jQuery(".js-home-post-load-more").hide();
                    }
                    jQuery(".js-home-post-load-more").attr('data-paged', paged);
                    jQuery(".c-kc-teaser__item.c-kc-teaser__item--50.c-kc-teaser__item--33:last").after(data);
                    jQuery(".js-home-post-load-more").prop( "disabled", false );
                }
                //console.log(data);
                //console.log("get data");

            }
        });
    })
})