if ($('.js-expandable-content').length) {
    $('.js-expandable-content').each(function () {
        let expandableContent = $(this);
        let moreButton = $(this).find('.js-expandable-content-more-button');
        let lessButton = $(this).find('.js-expandable-content-less-button');

        moreButton.on('click', function () {
            expandableContent.addClass('is-expanded');
            expandableContent.find('.is-hidden').removeClass('is-hidden').addClass('is-visible');
            expandableContent.find('.is-visible').first().attr('tabindex', -1).focus();
        });
        lessButton.on('click', function () {
            expandableContent.removeClass('is-expanded');
            expandableContent.find('.is-visible').addClass('is-hidden').removeClass('is-visible');
            moreButton.focus();
        });
    });
}