/**
 * Disclaimer footer
 */
let linkDisclaimer = $('.js-hero-home-link-disclaimer');
let disclaimerFooter = $('#disclaimer-home');

if (linkDisclaimer.length) {
    linkDisclaimer.on('click', function (event) {
        var target = linkDisclaimer.attr('href');
        event.preventDefault();
        disclaimerFooter.attr('tabindex', -1)
        $('html').animate({
            scrollTop: $(target).offset().top
        }, function () {
            setTimeout(function () {
                disclaimerFooter.focus();
                
            });
            
        });

        window.history.pushState('disclaimer-home', '', '/#disclaimer-home');
    })
}