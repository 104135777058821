var faq_first_el;
var faq_last_el;
var faqsAsideLink = jQuery(".js-faqs-aside-link");
/*
jQuery(function() {
    //jQuery(".js-faqs-aside-link").on("focusin", function() {
    if (faqsAsideLink.length > 0) {
        faqsAsideLink.on({
            "mousedown": function(event) {
                if (event.button == 0) {
                    //event.preventDefault();
                    console.log("ingreso con click");
                    //e.stopPropagation();
                    faq_focus_in(jQuery(this));
                }
            },
            "keydown": function(event) {
                if (event.key == 'Enter' || event.key == ' ') {
                    console.log("ingreso con enter");
                    //event.preventDefault();
                    faq_focus_in(jQuery(this));
                }

            }
        });
    }
    if (faqsAsideLink.length > 0 && jQuery(".c-faqs-questions-list__item").length > 0) {
        jQuery('.c-faqs-questions-list__item').on('keydown', function(e) {
            if (e.key == "Tab") {
                var currentfocus = jQuery(":focus");
                if (e.shiftKey) {
                    //console.log(faq_first_el);
                    //console.log(currentfocus.is(faq_first_el));
                    //console.log(currentfocus == faq_first_el);
                    if (currentfocus.is(faq_first_el)) {
                        e.preventDefault();
                        faq_first_el = null;
                        faq_last_el = null;
                        goToFaqMenuItem(jQuery(this), "prev");
                    }
                } else {
                    //console.log(faq_last_el);
                    //console.log(currentfocus.is(faq_last_el));
                    if (currentfocus.is(faq_last_el)) {
                        e.preventDefault();
                        faq_first_el = null;
                        faq_last_el = null;
                        goToFaqMenuItem(jQuery(this), "next");
                    }
                }
            }
        });
    }
});
*/
function goToFaqMenuItem(currElement, newPos) {
    var currentpos = jQuery(".js-faqs-aside-link.is-active").closest('li').index();
    var tmp = 0;

    switch (newPos) {
        case 'prev':
            tmp = (currentpos - 1 >= 0) ? currentpos - 1 : 0;
            break;

        case 'next':
            tmp = (currentpos + 1 <= faqsAsideLink.length - 1) ? currentpos + 1 : faqsAsideLink.length - 1;
            break;
    }

    faqsAsideLink.removeClass('is-active');
    faqsAsideLink.eq(tmp)[0].focus();
}

function faq_focus_in(el) {
    el.addClass('is-active');
    var target = el.attr("href");
    console.log(target);
    var focusElements_str = "a,input,button";
    var focusElements = jQuery(target).find(focusElements_str);
    faq_first_el = focusElements.first();
    faq_last_el = focusElements.last();
    jQuery(target)[0].focus();

}